// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/buildhome/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/buildhome/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manifesto-tsx": () => import("/opt/buildhome/repo/src/pages/manifesto.tsx" /* webpackChunkName: "component---src-pages-manifesto-tsx" */),
  "component---src-pages-nominate-values-tsx": () => import("/opt/buildhome/repo/src/pages/nominate-values.tsx" /* webpackChunkName: "component---src-pages-nominate-values-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("/opt/buildhome/repo/src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-propose-integration-tsx": () => import("/opt/buildhome/repo/src/pages/propose-integration.tsx" /* webpackChunkName: "component---src-pages-propose-integration-tsx" */),
  "component---src-pages-request-language-tsx": () => import("/opt/buildhome/repo/src/pages/request-language.tsx" /* webpackChunkName: "component---src-pages-request-language-tsx" */),
  "component---src-pages-suggest-feature-tsx": () => import("/opt/buildhome/repo/src/pages/suggest-feature.tsx" /* webpackChunkName: "component---src-pages-suggest-feature-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("/opt/buildhome/repo/src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */)
}

